// color themes (light, dark)
:root {  
    --waver-light: url(/assets/icons/waver/waver-man-light.gif);
    --waver-dark: url(/assets/icons/waver/waver-man-dark.gif);

    --mdc-theme-primary:  var(--blue-60);;
   
    // (light-colors , dark-colors)
    $colors: (
        --header-color:(--blue-82, --blue-82),
        --title-color:(--blue-80, --snow),
        --text-color: (--blue-95, --snow),
        --text-color-placeholder: (--blue-95-60, --snow),
        --text-grey-color: (--charcoal-60, --charcoal-10),
        --text-grey-light-color: (--blue-80, --blue-80),
        --bg-white-color: (--snow, --charcoal-95),
        --bg-card-color: (--snow, --blue-98),
        --app-bg-color: (--snow, --charcoal-90),
        --bg-color: (--blue-10, --blue-95),
        --bg-light-color: (--blue-5, --charcoal-85),
        --btn-primary-color: (--blue-60, --blue-30),
        --btn-dark-color: (--blue-60, --blue-30),
        --btn-dark-color-transparent: (--blue-60,--transparent),    
        --btn-dark-outline-color: (--transparent, --blue-92),
        --btn-dark-outline-border: (--blue-90,  --blue-98),
        --btn-light-color: (--blue-20, --blue-20),
        --btn-light-color-hover: (--blue-40, --blue-40),
        --btn-bg-hover: (--blue-90, --blue-90),
        --btn-text-color: (--blue-5, --blue-98),
        --nav-color: (--blue-20, --blue-97),
        --border-color: (--charcoal-10, --charcoal-10),
        --border-color-light: (--charcoal-5, --charcoal-95),
        --border-color-hover: (--blue-60, --blue-60),
        --text-color2: (--grey-85, --snow ),
        --text-color3: (--grey-80, --snow ),
        --text-color4: (--blue-83, --snow ),
        --text-color5: (--blue-grey, --snow ),
        --text-color6:(--charcoal-75, --snow),
        --text-color7: (--blue-83, --blue-30 ),
        --text-color-light:(--blue-30, --blue-30),
        --text-dark-color: (--blue-80, --snow),        
        --text-color8: (--blue-90, --blue-82),
        --text-color9: (--grey-85, --blue-30 ),
        --number-input-dark: (--blue-90, --blue-87),    
        --light-background: (--blue-7, --blue-91),
        --dark-background: (--blue-75, --blue-93 ),
        --dark-light-btn: (--blue-75, --blue-30 ),
        --side-bar-color:(--charcoal-40, --blue-30-30),
        --side-bar-color-active:(--blue-90, --blue-30),
        --text-default-color: (--charcoal-90, --snow ),
        --light-grey-btn: (--charcoal-10, --charcoal-95),
        --focus-input:(--transparent, --blue-85),
        --input-bg:(--snow, --blue-99),    
        --tour-bg:( --blue-30, --blue-98),
        --tour-title:(--blue-90, --blue-82),    
        --tour-text:( --charcoal-95, --blue-30),
        --tour-button-bg:( --blue-90, --blue-30),
        --tour-button-border:( --blue-30, --blue-30),
        --tour-button-text:(--blue-30, --charcoal-95 ),
        --bg-grey: (--grey-5, --charcoal-95),
        --kpi-header: (--blue-70, --blue-96 ),
        --kpi-odd: (--blue-35, --blue-99 ), 
        --kpi-even: (--blue-6, --blue-96 ),  
        --waver-img:(--waver-light, --waver-dark),
        --text-fade:(--charcoal-20, --charcoal-75),
        --welcome-text:(--charcoal-65, --blue-30)
    );

    .theme-blue-light {
        @each $name, $color in $colors {
            #{$name}: var(nth($color, 1));
        }
        }
        
    .theme-blue-dark {
        @each $name, $color in $colors {
            #{$name}: var(nth($color, 2));
        }
    }
}

