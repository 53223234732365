/* Navbar */
/* Animation Mixins */
@-webkit-keyframes dropdownAnimation {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0); } }
@keyframes dropdownAnimation {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0); } }

.dropdownAnimation, .nav .nav-item.dropdown .navbar-dropdown,
.navbar-nav .nav-item.dropdown .navbar-dropdown {
  -webkit-animation-name: dropdownAnimation;
  animation-name: dropdownAnimation;
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

.infinite-spin {
  -webkit-animation-name: spin;
  animation-name: spin;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes menufadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes menufadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.menufadeInLeft {
  -webkit-animation-name: menufadeInLeft;
  animation-name: menufadeInLeft; }

@keyframes dropdownAnimation {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    -webkit-transform: translate3d(0, 0px, 0);
    transform: translate3d(0, 0px, 0); } }

.purchase-banner-active .container-scroller {
  margin-top: 55px; }

.page-body-wrapper {
  min-height: calc(100vh - 63px);
  background: #F3F3F3;
  padding-left: 0;
  padding-right: 0; }
  .page-body-wrapper.full-page-wrapper {
    width: 100%;
    min-height: 100vh; }

.main-panel {
  min-height: calc(100vh - 63px);
  margin-left: auto; }

.content-wrapper {
  padding: 1.5rem 0;
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }

.nav .nav-item,
.navbar-nav .nav-item {
line-height: 1; }
  @media (max-width: 992px) {
    .nav .nav-item.dropdown,
    .navbar-nav .nav-item.dropdown {
      position: unset; } }
  .nav .nav-item.dropdown .dropdown-toggle:after,
  .navbar-nav .nav-item.dropdown .dropdown-toggle:after {
    border: none;
    content: "\f0d7";
    font: normal normal normal 28px/1 "FontAwesome";
    font-size: inherit;
    text-rendering: auto;
    line-height: inherit;
    vertical-align: 0; }
  .nav .nav-item.dropdown .count-indicator,
  .navbar-nav .nav-item.dropdown .count-indicator {
    position: relative;
    text-align: center; }
    .nav .nav-item.dropdown .count-indicator i,
    .navbar-nav .nav-item.dropdown .count-indicator i {
      font-size: 21px;
      margin-right: 0;
      vertical-align: middle; }
    .nav .nav-item.dropdown .count-indicator .count,
    .navbar-nav .nav-item.dropdown .count-indicator .count {
      position: absolute;
      left: 50%;
      width: 1rem;
      height: 1rem;
      border-radius: 100%;
      background: #FF0017;
      color: #ffffff;
      font-size: 11px;
      top: -1px;
      font-weight: 600;
      line-height: 1rem;
      border: none;
      text-align: center; }
    .nav .nav-item.dropdown .count-indicator:after,
    .navbar-nav .nav-item.dropdown .count-indicator:after {
      display: none; }
  .nav .nav-item.dropdown i,
  .navbar-nav .nav-item.dropdown i {
    margin-right: 0.5rem;
    vertical-align: middle; }
    .rtl .nav .nav-item.dropdown i, .rtl
    .navbar-nav .nav-item.dropdown i {
      margin-left: 0.5rem;
      margin-right: 0; }
  .nav .nav-item.dropdown .navbar-dropdown,
  .navbar-nav .nav-item.dropdown .navbar-dropdown {
    font-size: 0.9rem;
    margin-top: 0;
    position: absolute;
    right: 0;
    left: auto;
    border: 1px solid rgba(182, 182, 182, 0.1);
    padding: 0 0 20px;
    min-width: 100%;
    border-radius: 2px;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.13);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.13);
    overflow: hidden; }
    .rtl .nav .nav-item.dropdown .navbar-dropdown, .rtl
    .navbar-nav .nav-item.dropdown .navbar-dropdown {
      right: auto;
      left: 0; }
    @media (max-width: 991px) {
      .nav .nav-item.dropdown .navbar-dropdown,
      .navbar-nav .nav-item.dropdown .navbar-dropdown {
        right: -85px; } }
    .nav .nav-item.dropdown .navbar-dropdown.dropdown-left,
    .navbar-nav .nav-item.dropdown .navbar-dropdown.dropdown-left {
      left: 0;
      right: auto; }
    .nav .nav-item.dropdown .navbar-dropdown .badge,
    .navbar-nav .nav-item.dropdown .navbar-dropdown .badge {
      margin-left: 2.5rem; }
      .rtl .nav .nav-item.dropdown .navbar-dropdown .badge, .rtl
      .navbar-nav .nav-item.dropdown .navbar-dropdown .badge {
        margin-left: 0;
        margin-right: 1.25rem; }
      @media (max-width: 991px) {
        .nav .nav-item.dropdown .navbar-dropdown .badge,
        .navbar-nav .nav-item.dropdown .navbar-dropdown .badge {
          margin-right: 0.5rem; }
          .rtl .nav .nav-item.dropdown .navbar-dropdown .badge, .rtl
          .navbar-nav .nav-item.dropdown .navbar-dropdown .badge {
            margin-left: 0.5rem;
            margin-right: 0; } }
    .nav .nav-item.dropdown .navbar-dropdown .dropdown-item,
    .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0;
      padding: 7px 25px; }
      .nav .nav-item.dropdown .navbar-dropdown .dropdown-item i,
      .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item i {
        font-size: 24px; }
      .nav .nav-item.dropdown .navbar-dropdown .dropdown-item .ellipsis,
      .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .ellipsis {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis; }
      .nav .nav-item.dropdown .navbar-dropdown .dropdown-item .preview-icon,
      .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .preview-icon {
        width: 40px;
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
      .nav .nav-item.dropdown .navbar-dropdown .dropdown-item .small-text,
      .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-item .small-text {
        font-size: 0.75rem; }
    .nav .nav-item.dropdown .navbar-dropdown .dropdown-divider,
    .navbar-nav .nav-item.dropdown .navbar-dropdown .dropdown-divider {
      margin: 0; }

.card {
  -webkit-box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.02);
  box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.02); }

.navbar.horizontal-layout {
  font-family: "roboto", sans-serif;
  background: #ffffff;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08); }
  .navbar.horizontal-layout .nav-top {
    padding: 0px 15px; }
    .navbar.horizontal-layout .nav-top .navbar-brand-wrapper {
      width: 185px;
      height: 63px; }
      .navbar.horizontal-layout .nav-top .navbar-brand-wrapper .navbar-brand {
        color: #ffffff;
        font-size: 1.5rem;
        line-height: 55px;
        margin-right: 0;
        padding: 0.25rem 0; }
        .navbar.horizontal-layout .nav-top .navbar-brand-wrapper .navbar-brand:active, .navbar.horizontal-layout .nav-top .navbar-brand-wrapper .navbar-brand:focus, .navbar.horizontal-layout .nav-top .navbar-brand-wrapper .navbar-brand:hover {
          color: #424546; }
        .navbar.horizontal-layout .nav-top .navbar-brand-wrapper .navbar-brand img {
          max-width: 100%;
          height: 28px;
          margin: auto;
          vertical-align: middle; }
      .navbar.horizontal-layout .nav-top .navbar-brand-wrapper .brand-logo-mini {
        display: none; }
        .navbar.horizontal-layout .nav-top .navbar-brand-wrapper .brand-logo-mini img {
          width: auto;
          max-width: 100%;
          height: 28px;
          margin-left: 0; }
      @media (max-width: 576px) {
        .navbar.horizontal-layout .nav-top .navbar-brand-wrapper {
          width: 70px; }
          .navbar.horizontal-layout .nav-top .navbar-brand-wrapper .brand-logo {
            display: none; }
          .navbar.horizontal-layout .nav-top .navbar-brand-wrapper .brand-logo-mini {
            display: block; } }
    .navbar.horizontal-layout .nav-top .navbar-menu-wrapper {
      color: #ffffff;
      padding-left: 15px;
      width: calc(100% - 185px);
      position: relative; }
      @media (max-width: 992px) {
        .navbar.horizontal-layout .nav-top .navbar-menu-wrapper {
          position: unset; } }
      .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .search-box {
        -webkit-transition: 0.3s ease;
        transition: 0.3s ease;
        -webkit-transition-property: position, width, height, background-color;
        transition-property: position, width, height, background-color; }
        .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .search-box .input-group-text {
          padding-right: 0;
          border-top: none;
          border-right: none;
          border-bottom: none; }
        .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .search-box .form-control {
          border: none;
          font-weight: 600;
          -webkit-transition: 0.3s ease;
          transition: 0.3s ease;
          -webkit-transition-property: position, width, height, background-color;
          transition-property: position, width, height, background-color; }
        .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .search-box .search-close {
          display: none; }
        .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .search-box.search-mode {
          position: absolute;
          top: 0px;
          right: 0;
          left: 0;
          bottom: 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          z-index: 99;
          height: 100%;
          width: 100%; }
          .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .search-box.search-mode .input-group-text {
            font-size: 24px; }
          .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .search-box.search-mode .form-control {
            height: 100%;
            font-size: 20px;
            font-weight: 500; }
            .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .search-box.search-mode .form-control::-webkit-input-placeholder {
              font-size: inherit;
              font-weight: inherit; }
            .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .search-box.search-mode .form-control:-moz-placeholder {
              font-size: inherit;
              font-weight: inherit; }
            .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .search-box.search-mode .form-control::-moz-placeholder {
              font-size: inherit;
              font-weight: inherit; }
            .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .search-box.search-mode .form-control:-ms-input-placeholder {
              font-size: inherit;
              font-weight: inherit; }
          .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .search-box.search-mode .search-close {
            display: block;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 0;
            z-index: 99;
            font-size: 24px;
            padding: 10px;
            color: #2196f3; }
      .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-nav .nav-item {
          margin-left: 1rem;
          margin-right: 1rem; }
          .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-nav .nav-item .nav-link {
            color: #202339;
            font-size: 12px;
            vertical-align: middle; }
            .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-nav .nav-item .nav-link i {
              font-size: 1.25rem;
              vertical-align: middle; }
          .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            border-left: 1px solid #dee2e6;
            padding-left: 20px;
            margin-left: 25px; }
            .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown .dropdown-toggle:after {
              content: "\F1d9";
              font-family: "Material Design Icons";
              font-size: 25px; }
            .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown .nav-link {
              padding-top: 0;
              padding-bottom: 0; }
              .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown .nav-link .wrapper {
                color: #202339;
                text-align: right; }
                .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown .nav-link .wrapper .profile-text {
                  font-size: 16px;
                  font-weight: 500;
                  margin-bottom: 5px; }
                .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown .nav-link .wrapper .user-designation {
                  opacity: 0.66; }
              .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-nav .nav-item.user-dropdown .nav-link .display-avatar {
                margin-left: 10px; }
        .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-nav.quick-link .nav-item .nav-link {
          margin-right: 10px;
          font-weight: 500;
          font-size: 14px; }
        .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-nav.quick-link .nav-item.active .nav-link {
          color: #2196f3; }
      .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-toggler {
        display: none; }
        @media (max-width: 768px) {
          .navbar.horizontal-layout .nav-top .navbar-menu-wrapper .navbar-toggler {
            display: block;
            padding-right: 0; } }
      @media (max-width: 576px) {
        .navbar.horizontal-layout .nav-top .navbar-menu-wrapper {
          width: calc(100% - 70px); } }
  .navbar.horizontal-layout .nav-bottom {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition-duration: 3s;
    transition-duration: 3s;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    background: #1089CE; }
    @media (max-width: 768px) {
      .navbar.horizontal-layout .nav-bottom {
        display: none; } }
    .navbar.horizontal-layout .nav-bottom .page-navigation {
      float: left;
      position: relative;
      width: 100%;
      height: 50px;
      z-index: 99;
      background: #1089CE;
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
      -webkit-transition-property: background, -webkit-box-shadow;
      transition-property: background, -webkit-box-shadow;
      transition-property: background, box-shadow;
      transition-property: background, box-shadow, -webkit-box-shadow; }
      @media (max-width: 768px) {
        .navbar.horizontal-layout .nav-bottom .page-navigation {
          border: none; } }
      .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item {
        line-height: 1;
        width: 12.5%;
        text-align: center; }
        @media (max-width: 768px) {
          .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item {
            display: block;
            width: 100%;
            border-right: none;
            text-align: left;
            background: #1089CE; } }
        .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item:last-child {
          border-right: none; }
        .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item > .nav-link {
          color: #757575;
          line-height: 1;
          font-weight: 600;
          -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
          height: 50px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          white-space: nowrap; }
          @media (max-width: 768px) {
            .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item > .nav-link {
              -webkit-box-pack: start;
              -ms-flex-pack: start;
              justify-content: start;
              background: #1089CE; } }
          .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item > .nav-link .link-icon {
            margin-right: 10px;
            font-size: 1.3rem;
            color: #ffffff; }
            .rtl .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item > .nav-link .link-icon {
              margin-right: 0;
              margin-left: 10px; }
          .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item > .nav-link .menu-arrow {
            margin-left: 5px;
            font-size: 15px;
            display: inline-block;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transform-origin: center;
            transform-origin: center;
            -webkit-transition-duration: 0.25s;
            transition-duration: 0.25s; }
            .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item > .nav-link .menu-arrow:before {
              font: normal normal normal 24px/1 "Material Design Icons";
              content: "\F35D";
              font-size: inherit; }
        .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item:hover .submenu {
          display: block;
          background: #ffffff; color: #0066ff !important; }
        .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item:hover > .nav-link {
          background: #ffffff;color: #0066ff !important; }
          .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item:hover > .nav-link .menu-arrow {
            -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }
        .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item.active > .nav-link {
          background: #52A6E5;
          color: #ffffff; }
          .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item.active > .nav-link .link-icon,
          .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item.active > .nav-link .menu-arrow {
            color: #ffffff; }
        .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item .submenu {
          display: none;
          -webkit-animation-name: dropdownAnimation;
          animation-name: dropdownAnimation;
          -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }
          .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item .submenu ul {
            list-style-type: none;
            padding-left: 0; }
            .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item .submenu ul li {
              display: block;
              line-height: 20px; }
              .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item .submenu ul li a {
                display: block;
                padding: 5px 10px;
                font-weight: 600;
                color: #000;
                text-decoration: none;
                text-align: left;
                margin: 4px 0;
                font-size: 14px;
                font-weight: 400;
                opacity: 0.8;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 100%;
                white-space: nowrap;
                -webkit-transition-duration: 0.2s;
                transition-duration: 0.2s;
                -webkit-transition-property: background;
                transition-property: background;
                border-radius: 6px; }
                .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item .submenu ul li a:hover {
                  background: rgba(33, 150, 243, 0.1); }
              .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item .submenu ul li.active a {
                background: rgba(33, 150, 243, 0.1); }
        .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item:not(.mega-menu) .submenu {
          position: relative;
          top: -100%; }
          @media (max-width: 768px) {
            .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item:not(.mega-menu) .submenu {
              top: 0; } }
          .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item:not(.mega-menu) .submenu ul {
            padding: 15px; }
            @media (min-width: 768px) {
              .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item:not(.mega-menu) .submenu ul {
                position: absolute;
                width: calc(100% - 1px);
                left: 0;
                right: 0;
                top: 50px;
                z-index: 999;
                background: #ffffff;
                border-top: none;
                -webkit-box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.06);
                box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.06); } }
            @media (max-width: 768px) {
              .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item:not(.mega-menu) .submenu ul {
                position: relative;
                top: 0;
                padding-top: 20px;
                -webkit-box-shadow: none;
                box-shadow: none; } }
            @media (max-width: 768px) {
              .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item:not(.mega-menu) .submenu ul {
                padding: 0 23px; } }
        .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item.mega-menu .submenu {
          top: calc($navbar-bottom-height - 2px);
          left: 0;
          padding: 25px; }
          @media (min-width: 768px) {
            .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item.mega-menu .submenu {
              position: absolute;
              width: calc(100% - 1px);
              left: 0;
              right: 0;
              top: 50px;
              z-index: 999;
              background: #ffffff;
              border-top: none;
              -webkit-box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.06);
              box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.06); } }
          @media (max-width: 768px) {
            .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item.mega-menu .submenu {
              position: relative;
              top: 0;
              padding-top: 20px;
              -webkit-box-shadow: none;
              box-shadow: none; } }
          .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item.mega-menu .submenu .category-heading {
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: #2196f3;
            padding-bottom: 15px;
            border-bottom: 1px solid rgba(222, 226, 230, 0.6); }
          @media (max-width: 768px) {
            .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item.mega-menu .submenu {
              padding: 20px 32px; }
              .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item.mega-menu .submenu .col-group-wrapper {
                margin-left: 0;
                margin-right: 0; }
                .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item.mega-menu .submenu .col-group-wrapper .col-group {
                  padding-left: 0;
                  padding-right: 0;
                  margin-bottom: 20px; }
                  .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item.mega-menu .submenu .col-group-wrapper .col-group .category-heading {
                    margin-bottom: 0; }
                    .navbar.horizontal-layout .nav-bottom .page-navigation > .nav-item.mega-menu .submenu .col-group-wrapper .col-group .category-heading:after {
                      display: none; } }
    @media (max-width: 768px) {
      .navbar.horizontal-layout .nav-bottom.header-toggled {
        display: block;
        background: transparent; } }
    .navbar.horizontal-layout .nav-bottom.fixed-top {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999; }
      @media (max-width: 768px) {
        .navbar.horizontal-layout .nav-bottom.fixed-top {
          position: relative; } }
      .navbar.horizontal-layout .nav-bottom.fixed-top .page-navigation {
        margin-bottom: 0;
        border-bottom: none;
        -webkit-box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.06);
        box-shadow: 0 8px 5px 0 rgba(0, 0, 0, 0.06); }
      .purchase-banner-active .navbar.horizontal-layout .nav-bottom.fixed-top {
        top: 55px; }

/* Footer */
/*.footer {
  background: #F3F3F3;
  padding: 20px 1rem;
  transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  border-top: 1px solid #dee2e6;
  font-size: calc(0.875rem - 0.05rem);
  font-family: "roboto", sans-serif; }
  .footer a {
    color: #19d895;
    font-size: inherit; }
  @media (max-width: 991px) {
    .footer {
      margin-left: 0;
      width: 100%; } }*/

/* Full Calendar */
#calendar .fc-header-toolbar {
  background: #ffffff;
  padding: 28px 0 80px;
  margin-bottom: 0; }
  #calendar .fc-header-toolbar .fc-button-group {
    border-radius: 5px; }
    #calendar .fc-header-toolbar .fc-button-group .fc-button {
      -webkit-box-shadow: none;
      box-shadow: none;
      padding: 1px 12px;
      margin-right: 0;
      font-size: 0.9375rem;
      font-weight: 400;
      line-height: 1.33;
      border: none;
      background: #2196f3;
      color: #ffffff;
      text-shadow: none;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s; }
      #calendar .fc-header-toolbar .fc-button-group .fc-button:first-letter {
        text-transform: uppercase; }
      #calendar .fc-header-toolbar .fc-button-group .fc-button.fc-state-active {
        background: #0d8aee;
        color: #ffffff; }
      #calendar .fc-header-toolbar .fc-button-group .fc-button.fc-next-button, #calendar .fc-header-toolbar .fc-button-group .fc-button.fc-prev-button {
        background: #ffffff;
        color: #2196f3;
        margin-right: 7px;
        height: 2.0625rem;
        width: 2.25rem;
        border: none; }
        #calendar .fc-header-toolbar .fc-button-group .fc-button.fc-next-button span, #calendar .fc-header-toolbar .fc-button-group .fc-button.fc-prev-button span {
          top: -3px;
          right: 9px; }
          #calendar .fc-header-toolbar .fc-button-group .fc-button.fc-next-button span:after, #calendar .fc-header-toolbar .fc-button-group .fc-button.fc-prev-button span:after {
            font: normal normal normal 24px/1 "Material Design Icons";
            display: block;
            line-height: 21px; }
      #calendar .fc-header-toolbar .fc-button-group .fc-button.fc-prev-button span:after {
        content: "\F141"; }
      #calendar .fc-header-toolbar .fc-button-group .fc-button.fc-next-button span:after {
        content: "\F142"; }
  #calendar .fc-header-toolbar .fc-today-button {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 1px 12px;
    border-radius: 2px;
    margin-right: 4px;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.33;
    border: none;
    background: #2196f3;
    color: #ffffff;
    text-shadow: none;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s; }
    #calendar .fc-header-toolbar .fc-today-button:first-letter {
      text-transform: uppercase; }
  #calendar .fc-header-toolbar .fc-center h2 {
    font-size: 1.125rem;
    font-weight: 600;
    text-transform: uppercase; }

#calendar .fc-view-container .fc-view {
  background: #F3F3F3; }
  #calendar .fc-view-container .fc-view table thead.fc-head {
    border: none; }
    #calendar .fc-view-container .fc-view table thead.fc-head tr td .fc-widget-header {
      border: none; }
      #calendar .fc-view-container .fc-view table thead.fc-head tr td .fc-widget-header table thead {
        border: none; }
        #calendar .fc-view-container .fc-view table thead.fc-head tr td .fc-widget-header table thead tr th.fc-day-header {
          text-transform: uppercase;
          padding: 0.4375rem 0;
          border-color: #dee2e6;
          font-size: 0.875rem; }
    #calendar .fc-view-container .fc-view table thead.fc-head tr td.fc-head-container {
      border-color: #f7f7f7;
      border-bottom: none; }
  #calendar .fc-view-container .fc-view table tbody.fc-body {
    background: #ffffff; }
    #calendar .fc-view-container .fc-view table tbody.fc-body tr td.fc-widget-content {
      border-color: #dee2e6; }
      #calendar .fc-view-container .fc-view table tbody.fc-body tr td.fc-widget-content .fc-day-grid-container .fc-day-grid .fc-row {
        border-color: #dee2e6; }
        #calendar .fc-view-container .fc-view table tbody.fc-body tr td.fc-widget-content .fc-day-grid-container .fc-day-grid .fc-row .fc-bg table tr td {
          border-color: #dee2e6; }
        #calendar .fc-view-container .fc-view table tbody.fc-body tr td.fc-widget-content .fc-day-grid-container .fc-day-grid .fc-row .fc-content-skeleton table thead tr td.fc-day-top {
          padding: 8px;
          font-size: 0.875rem;
          font-weight: 600; }
        #calendar .fc-view-container .fc-view table tbody.fc-body tr td.fc-widget-content .fc-day-grid-container .fc-day-grid .fc-row .fc-content-skeleton table tbody tr td.fc-event-container .fc-h-event {
          background: #19d895;
          border: none;
          padding: 6px 12px 6px 22px;
          font-weight: 500;
          color: #ffffff;
          font-size: 12px;
          border-radius: 4px; }
        #calendar .fc-view-container .fc-view table tbody.fc-body tr td.fc-widget-content .fc-day-grid-container .fc-day-grid .fc-row .fc-content-skeleton table tbody tr td.fc-more-cell {
          font-weight: 600;
          color: #252C46;
          padding: 0 7px; }

/*# sourceMappingURL=style.css.map */
