body{
    background-color: var(--app-bg-color);
    color: var(--text-color);
}

.close, .modal .modal-dialog .modal-content .modal-header .close span {
  font-size: 38px;
  line-height: 15px;
}

.btn-img {
  background: var(--green-70);
  border-radius: 7px;
}

.bs-datepicker-container {
  padding: 0px;
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: var(--header-color);

}

/*.swal2-popup {
  background-color: var(--bg-white-color) !important;
  color: var(--text-default-color) !important;

  &* {
    color: var(--text-default-color) !important;
  }
}*/

.form-control {
  display: block;
  width: 100%;
  height: calc(3rem + 2px);
  padding: .375rem .75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text-color);
  background-color: var(--input-bg);
  background-clip: padding-box;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  font-size: small;
}

.form-control:focus{
background-color: var(--focus-input);
color: unset;
border: 2px solid var(--border-color-hover);
}

.form-control::placeholder, .mat-mdc-select-placeholder {
color: var(--text-color-placeholder);
}
.form-control:focus::placeholder {
color: var(--text-color);
}



.card {
color: var(--text-color);
background-color: var(--bg-card-color);
}


.input-style1{
  color: var(--title-color);
  &:focus, &::placeholder{
    color: var(--title-color);
  }
  ::ng-deep {
    .ng-option-label {
      color: var(--text-grey-color) !important;
    }
  }
}

.ng-select{
  color: var(--text-default-color) !important;
  &>*, & *,ng-select-container {
    background-color: transparent !important;
    color: var(--text-default-color) !important;
  }
  .ng-dropdown-panel{
    background-color: var(--bg-white-color) !important;
    color: var(--text-default-color) !important;
  }
  &.hide-clear .ng-clear-wrapper .ng-clear {
      display: none;
  }

}

.mat-mdc-select-value,
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--text-grey-color);
}

.ng-select{
  &.input-style1{
    color: var(--title-color) !important;
    &>*, & *, .ng-dropdown-panel {
      color: var(--title-color) !important;
    }
    .ng-option-label {
        color: var(--text-grey-color) !important;
    }
  }  
}


.border-top, .border-bottom {
  border-color: var(--border-color) !important;
}

.bg-grey, .bg-grey:focus, .bg-grey .ng-select-container{
  border-radius: 5px;
  border-color: var(--bg-grey) !important;
  background-color: var(--bg-grey) !important;
}

.apexcharts-text tspan {
  fill: var(--text-default-color);
}

.apexcharts-legend-text  {
  color: var(--text-color) !important;
}

.apexcharts-datalabel-value  {
  fill: var(--text-color) !important;
}


.animate-colors-transition {
  transition: background-color 0.3s;
}

.text-fade {
  color: var(--charcoal-20) !important;
}

.text-success {
  color: #22ad7c !important;
}

a.text-success:hover, a.text-success:focus {
  color: #119366 !important;
}

.text-muted {
  color: var(--charcoal-35) !important;
}

.border-primary {
  border-color: var(--btn-primary-color) !important;
  color: var(--btn-primary-color) !important;
}

.btn-dark-primary, .btn-light-primary, .btn-primary, .btn-danger, .btn {
  font-size: 16px !important;
  font-weight:500;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
}

.btn-primary, .btn-dark-primary{
  border-color: transparent !important;  
  background-color: var(--btn-primary-color)!important;
  color: var(--btn-text-color);
  &:hover, &:focus, &::placeholder {
    color: var(--btn-text-color);
    &:not([disabled]) {
      background: var(--btn-bg-hover);
    }
  }
  &:disabled, &.disabled {
    opacity: 0.6;
  }
}

.btn-dark-primary {
  background-color: var(--btn-dark-color)!important;
}

.btn-light-primary {
  border-color: transparent !important;  
  background-color: var(--btn-light-color)!important;
  color: var(--text-grey-light-color);
  &:hover {
    color: var(--text-grey-light-color);
    &:not([disabled]) {
      background: var(--btn-light-color-hover);
    }
  }
}

.btn-outline-dark-primary, .select-outline-dark-primary {
  background-color: var(--btn-dark-outline-color) !important;  
  border-color: var(--btn-dark-outline-border)!important;
  color: var(--btn-dark-color)!important;
  &:hover, &:focus, &::placeholder {
    color: var(--btn-dark-color);
    &:not([disabled]) {
      background-color: transparent !important;  
    }
  }
  &:disabled, &.disabled {
    opacity: 0.6;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s !important;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

::placeholder,
::-webkit-input-placeholder,  /* Chrome/Opera/Safari */
::-moz-placeholder,  /* Firefox 19+ */
:-ms-input-placeholder,  /* IE 10+ */
:-moz-placeholder /* Firefox 18- */
{
  color: var(--text-color-placeholder) ;  
  font-size: 16px;
  font-weight: 500;
}

.mat-menu-item, .mat-mdc-menu-item {
  color: var(--text-grey-color);
  height: 35px !important;
  min-height: 35px !important;
  font-size: 14px !important;
}

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  color: currentColor;
  font-size: 14px !important;
  line-height: 14px !important;
}


.mat-menu-panel,
.mat-mdc-menu-panel {
  min-height: 50px !important;
}
.mat-form-field,
.mat-mdc-form-field {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 500;
}

.mat-input-element::placeholder,
.mat-form-field-empty.mat-form-field-label,
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-mdc-input-element::placeholder,
.mat-mdc-form-field-empty.mat-mdc-form-field-label,
.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-label,
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label{
  color: var(--text-color-placeholder);
  font-size: 16px;
  font-weight: 500;
}


.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing
{
  border-color: var(--border-color-hover);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading, 
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--border-color);
 // background-color: var(--input-bg);
}



.mat-form-field-appearance-outline .mat-form-field-outline,
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
  color: var(--border-color);
}


.mat-form-field-appearance-outline .mat-form-field-outline-thick,
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick ,

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick,
.mat-mdc-form-field-appearance-outline.mat-mdc-focused .mat-mdc-form-field-outline-thick {
  color: var(--border-color-hover);
}

.mat-select-panel, .mat-mdc-select-panel {
  max-height: 300px !important;
}


.mat-menu-panel , .mat-mdc-menu-panel, .mdc-menu-surface.mat-mdc-select-panel {
  background-color: var(--bg-white-color) !important;
  .mat-menu-item, .mat-mdc-menu-item, .mat-mdc-option .mdc-list-item__primary-text {
    color: var(--text-grey-color);
  }
}

.select-outline-dark-primary {
  .mat-select-value, .mat-mdc-select-value {
      color: var(--btn-dark-color)!important;
  }
}




.slider-dark-primary{
  background-color: transparent;
  border: none;
  margin-top: 20px;
  height: 30px !important;
    .mat-slider-thumb,
    .mat-slider-thumb-label,
    .mat-slider-track-fill,

    .mat-mdc-slider-thumb,
    .mat-mdc-slider-thumb-label,
    .mat-mdc-slider-track-fill
    {
      background-color: var(--btn-dark-color)!important;
    }
    .mat-slider-thumb-label-text,
    .mat-mdc-slider-thumb-label-text {
      transform: rotate(0deg) !important;
      opacity: 1 !important;
    }
  
    .mat-slider-thumb-label,
    .mat-mdc-slider-thumb-label {
        transform: rotate(0deg) !important;
        border-radius: 5px !important;
        width: auto !important;
        height: 35px;
        width: auto;
        min-width: 40px;
        max-width: 150px;
        right: -20px !important;
        top: -45px !important;
        padding: 5px;
    }
    &.mat-slider:not(.mat-slider-disabled).mat-slider-thumb-label-showing .mat-slider-thumb,
    &.mat-mdc-slider:not(.mat-mdc-slider-disabled).mat-mdc-slider-thumb-label-showing .mat-mdc-slider-thumb {
      transform: scale(1.5) !important;
      border-radius: 50% 50% 0;
      transform: rotate(45deg)  !important;
      top: -25px;  
    }
} 

/* width */
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #1089CE; */
  background: linear-gradient(176deg, #f1f1f1 55%, #d0d0d0 88%);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(176deg, #f1f1f1 24%, #d0d0d0 88%);
}

.capital{
  &::first-letter{
    text-transform: capitalize;
  }
}

/** Custom Checkbox **/
input[type=checkbox].custom-check {
  position: relative;
  cursor: pointer;
 
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    border: 2px solid #EFEFEF;
    border-radius: 3px;
    background-color: #EFEFEF;
  }
  &:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    border: 2px solid var(--text-grey-light-color);
    border-radius: 3px;
    background-color: var(--text-grey-light-color);
  }
  &:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 3px;
    left: 7px;
  }
}

.swal2-modal {
  .swal2-close {
    font-size: 35px;
    padding: 10px;
    font-weight: bolder;
    &:hover {
      color: #ccc;
    }
  }
} 


.btn-save {
  background: var(--green-70);
  border-radius: 20px;
  color: #fff;
  padding: 6px 30px;
  &:focus , &:hover{
    color: #fff;
  }
}

/** TOUR GUIDE */
.cdk-overlay-container:has(.bdc-walk-popup) {  
  background: #00000050;
  pointer-events: unset;
  overflow: auto;
}

.cdk-overlay-container:not(:has(.bdc-walk-popup))
{
    z-index: 9999 !important;
}

div.mat-mdc-menu-panel.bdc-walk-popup .mat-mdc-menu-content, div.mat-mdc-menu-panel.bdc-walk-popup .mat-menu-content, div.mat-menu-panel.bdc-walk-popup .mat-mdc-menu-content, div.mat-menu-panel.bdc-walk-popup .mat-menu-content
{
    height: auto !important;
}

div.mat-mdc-menu-panel.bdc-walk-popup .title a.close,
div.mat-menu-panel.bdc-walk-popup .title a.close {
  background: none !important;
  &::before {
    content: "×";
    //font-size: 30px;
    color: var(--tour-text);
    font-weight: 300;
  }
}

div.mat-mdc-menu-panel.bdc-walk-popup .title .header, div.mat-menu-panel.bdc-walk-popup .title .header {
  color: var(--tour-title) !important;
}
div.mat-mdc-menu-panel.bdc-walk-popup , div.mat-menu-panel.bdc-walk-popup,
div.mat-mdc-menu-panel.bdc-walk-popup .container, div.mat-menu-panel.bdc-walk-popup .container {
  background-color: var(--tour-bg) !important;
  background-blend-mode: lighten;
  color: var(--tour-text);
}

div.mat-mdc-menu-panel.bdc-walk-popup, div.mat-menu-panel.bdc-walk-popup{
  border-color: transparent !important;
}
div.mat-mdc-menu-panel.bdc-walk-popup.arrow.mat-menu-after.horizontal:after, div.mat-menu-panel.bdc-walk-popup.arrow.mat-menu-after.horizontal:after,
div.mat-mdc-menu-panel.bdc-walk-popup.arrow.mat-menu-after.horizontal:before, div.mat-menu-panel.bdc-walk-popup.arrow.mat-menu-after.horizontal:before
{
  border-right-color: var(--tour-bg) !important;
}

div.mat-mdc-menu-panel.bdc-walk-popup .buttons button, div.mat-menu-panel.bdc-walk-popup .buttons button {
  color: var(--tour-button-text) !important;
  background:  var(--tour-button-bg);
  border-color: var(--tour-button-border);
  letter-spacing: 0;
}
div.mat-mdc-menu-panel.bdc-walk-popup, div.mat-menu-panel.bdc-walk-popup {
  min-width: 400px !important;
  box-shadow: 0px 0px 5px 5px #0003 !important;
}
div.mat-mdc-menu-panel.bdc-walk-popup .title, div.mat-menu-panel.bdc-walk-popup .title {
padding-left: 50px;
}