$gs-blue: #0066ff;
$gs-warning-red: #f5423c;
$gs-category-outline: rgb(115, 115, 115, .5);


.primary-color {
    color: $gs-blue
  }
  
  .primary-border-color {
    border-color: $gs-blue;
  }
  
  .warning-color {
    color: $gs-warning-red;
  }

// NEW Color Variables

:root {
  // Primary Colors
  --fire: #ff2934;
  --sun: #ffd129;
  --charcoal: #000000;
  --snow: #ffffff;

  // Accented
  --forest: #9ccc20;
  --wasabi: #29ffa5;
  --sky: #31cfff;
  --cyber: #2d49ff;
  --purpose: #7729ff;
  --pink: #ff00ff;

  // Shades
  --charcoal-95: #182525;
  --charcoal-90: #1a1a1a;
  --charcoal-85: #272727;
  --charcoal-80: #333333;
  --charcoal-70: #222;
  --charcoal-75: #444444;  
  --charcoal-65: #666666;
  --charcoal-60: #6D6D6D;
  --charcoal-50: #787878;
  --charcoal-40: #999999;
  --charcoal-35: #A9A9A9;
  --charcoal-30: #C9C6C6;
  --charcoal-20: #D3D3D3;
  --charcoal-15: #DCDCDC;
  --charcoal-10: #e5e5e5;
  --charcoal-5: #EDEDED;
  --charcoal-4: #f5f5f5;
  
  
  --magma: #FF6131;
  --lava: #FF992D;
  --dark-fire: #E74C3C;

  // Green Shades
  --green-99-9: #182B2B;
  --green-99-8: #192b2b;
  --green-99: #023240;
  --green-98: #203939;
  --green-97: #145454;
  --green-96: #194747;
  --green-95: #034949;  
  --green-95-60: rgba(47, 79, 79, 0.6);
  --green-94: #1f4343;
  --green-93: #0F5C5C;
  --green-92: #276161;
  --green-91: #0b6363;
  --green-90-30: #00808030;
  --green-90: #008080;
  --green-87: #11807f;
  --green-85: #4C7E7D;
  --green-83: #708A90;
  --green-82: #779997;
  --green-80: #729CA2;
  --green-75: #679CA1;
  --green-70: #70A7AF;  
  --green-60: #85c2c2;
  --green-40: #AECDC9;
  --green-35: rgba(112, 167, 175, 0.5);
  --green-30: #C7D7DA;
  --green-30-30: #C7D7DA30;  
  --green-20: #DCE9EC;
  --green-10: #ECF3F5;
  --green-7: #EBF3F5;
  --green-6: rgba(112, 167, 175, 0.1);
  --green-5: #F5F7F9;


 

  // blue shades
  --blue-99: #082550;
  --blue-98: #202a39;
  --blue-97: #142b54;
  --blue-96: #193647;
  --blue-95: #150d5d;
  --blue-95-60: rgba(21, 13, 93, 0.6);
  --blue-93: #0f1e5c;
  --blue-944: #150d5d;  
  --blue-92: #49418f;
  --blue-83: #708390;
  --blue-82: #708390;
  --blue-91: #223595;
  --blue-90: #263CAA;
  --blue-90-30: #708390;
  --blue-87: #263CAA;
  --blue-80: #5873BF;
  --blue-85: #4f68ad;
  --blue-75: #5873BF;
  --blue-70: #4e62c7;
  --blue-60: #3550D2;
  --blue-40: #B3BFE0;
  --blue-30: #c3ceed;
  --blue-30-30: #c3ceed30;  
  --blue-20: #E5E8F9;
  --blue-10: #F2F3FC;
  --blue-7: #F2F3FC;
  --blue-5: #FAFAFE;

  --green-grey: rgba(47, 79, 79, 0.4);
  --blue-grey:  rgba(50, 47, 79, 0.4);
  --grey-80: #778899;
  --grey-85: #708090;
  --grey-5: #F6F6F6;

  --transparent: transparent;

  
  // Gradients
  --fire-sun: transparent linear-gradient(225deg, var(--sun) 0%, var(--fire) 100%) 0% 0% no-repeat padding-box;
  --fire-lava: transparent linear-gradient(225deg, var(--lava) 0%, var(--fire) 100%) 0% 0% no-repeat padding-box;
  --fire-magma: transparent linear-gradient(225deg, var(--magma) 0%, var(--fire) 100%) 0% 0% no-repeat padding-box;

}

