// color themes (light, dark)
:root {
  --waver-light: url(/assets/icons/waver/waver-man-light.gif);
  --waver-dark: url(/assets/icons/waver/waver-man-dark.gif);
  --mdc-theme-primary: var(--green-60);

  // (light-colors , dark-colors)
  $colors: (
    --header-color:(--green-82, --green-82),
    --title-color:(--green-80, --snow),
    --text-color: (--green-95, --snow),
    --text-color-placeholder: (--green-95-60, --snow),
    --text-grey-color: (--charcoal-60, --charcoal-10),
    --text-grey-light-color: (--green-80, --green-80),
    --bg-white-color: (--snow, --charcoal-95),
    --bg-card-color: (--snow, --green-94),
    --app-bg-color: (--snow, --charcoal-90),
    --bg-color: (--green-10, --green-92),
    --bg-light-color: (--green-5, --charcoal-85),
    --btn-primary-color: (--green-60, --green-30),
    --btn-dark-color: (--green-90, --green-30),
    --btn-dark-color-transparent: (--green-90,--transparent),
    --btn-dark-outline-color: (--transparent, --green-92),
    --btn-dark-outline-border: (--green-90,  --green-98),        
    --btn-light-color: (--green-20, --green-20),
    --btn-light-color-hover: (--green-40, --green-40),        
    --btn-bg-hover: (--green-92, --green-92),        
    --btn-text-color: (--green-5, --green-98),
    --nav-color: (--green-20, --green-97),
    --border-color: (--charcoal-10, --charcoal-95),
    --border-color-light: (--charcoal-5, --charcoal-95),
    --border-color-hover: (--green-60, --green-60),
    --text-color2: (--grey-85, --snow ),
    --text-color3: (--grey-80, --snow ),
    --text-color4: (--green-83, --snow ),
    --text-color5:(--green-grey, --snow ),
    --text-color6:(--charcoal-75, --snow),
    --text-color7: (--green-83, --green-30 ),
    --text-color8: (--green-90, --green-82),
    --text-color9: (--grey-85, --green-30 ),
    --text-color-light:(--green-30, --green-30),
    --number-input-dark: (--green-90, --green-87),    
    --text-dark-color: (--green-80, --snow),
    --light-background: (--green-7, --charcoal-95),        
    --dark-background: (--green-75, --green-93 ),
    --dark-light-btn: (--green-75, --green-30 ),
    --side-bar-color:(--charcoal-40, --green-30-30),
    --side-bar-color-active:(--green-90, --green-30),
    --text-default-color: (--charcoal-90, --snow ),
    --light-grey-btn: (--charcoal-10, --charcoal-95),
    --focus-input:(--transparent, --green-85),
    --input-bg:(--transparent, --green-99-8),
    --tour-bg:( --green-30, --green-98),
    --tour-title:(--green-90, --green-82),    
    --tour-text:( --charcoal-95, --green-30),
    --tour-button-bg:( --green-90, --green-30),
    --tour-button-border:( --green-30, --green-30),
    --tour-button-text:(--green-30, --charcoal-95 ),
    --bg-grey: (--grey-5, --charcoal-95),
    --kpi-header: (--green-70, --green-96 ),
    --kpi-odd: (--green-35, --green-99-9 ), 
    --kpi-even: (--green-6, --green-96 ),     
    --waver-img:(--waver-light, --waver-dark),
    --text-fade:(--charcoal-20, --charcoal-75),
    --welcome-text:(--charcoal-65, --green-30)
  );

  .theme-green-light {
      @each $name, $color in $colors {
          #{$name}: var(nth($color, 1));
      }
      }
      
  .theme-green-dark {
      @each $name, $color in $colors {
          #{$name}: var(nth($color, 2));
      }
  }
}

